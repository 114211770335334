var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"custom-table",attrs:{"headers":_vm.headers,"items-per-page":_vm.pagination,"items":_vm.items,"search":_vm.search,"footer-props":_vm.footerProps,"sort-by":['start_date'],"sort-desc":true},on:{"click:row":_vm.showItem},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pt-5"},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.items.length && _vm.exportOption),expression:"items.length && exportOption"}],attrs:{"outlined":"","rounded":"","small":"","loading":_vm.loading}},'v-btn',attrs,false),on),[_c('download-excel',{attrs:{"data":_vm.items,"fields":_vm.json_fields,"type":'csv',"name":'StatusPage_Records.csv',"before-generate":_vm.setLoading,"before-finish":_vm.setLoading}},[_vm._v(" Export ")])],1)]}}])},[_vm._v(" Export to CSV ")])],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filter"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.incident_type",fn:function(ref){
var item = ref.item;
return [(item.incident_type)?_c('span',{staticClass:"text-capitalize"},[_c(_vm.dictionary[item.incident_type],{tag:"component"})],1):_c('span',[_c('maintenance')],1)]}},{key:"item.service_impacted",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.service_impacted)+" ")])]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getDate(item.start_date))+" ")])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getDate(item.end_date))+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }